import React from 'react';

export default function About(props) {
return (
    <>
    <div className='container mx-auto mt-5 mb-5 col-12 text-center'>
        <div className="hd">Its free to use text utility tools.</div>
        <p>You can play with your texts here. It could be a funny thing for you.<br></br>Also while playing with your text you could do a lot of interesting things here.<br></br> Like count all characters of your text you can also say it as text length.<br></br>Count total no of words, capitalize each word and much more. <br></br>Intrestingly, after doing all your processings you can also copy it to your clipborad and paste anywhere you want.</p>
        <div className="row about-text-editor" style={{justifyContent:"center"}}>
            <div className="card col-md-3 col-12">
                <div className="card-content">
                    <div className="card-body"> <img className="img" src="/images/upppercase.png" />
                        <div className="shadow"></div>
                        <div className="card-title"> Convert all characters to uppercase </div>
                        <div className="card-subtitle">
                            <p> <small className="text-muted">You can convert all your text characters to uppercase in just one click, in no time.</small> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-md-3 col-12 ml-2">
                <div className="card-content">
                    <div className="card-body"> <img className="img" src="/images/lowercase.png" />
                        <div className="card-title"> Convert all characters to lowercase </div>
                        <div className="card-subtitle">
                            <p> <small className="text-muted"> You can convert all your text characters to lowercase in just one click, in no time.</small> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-md-3 col-12 ml-2">
                <div className="card-content">
                    <div className="card-body"> <img className="img rck" src="/images/capitalize.png" />
                        <div className="card-title"> Capitalize each word </div>
                        <div className="card-subtitle">
                            <p> <small className="text-muted">If you are looking to capitalize each word of your text. You can do it here with our added functionaity, which will do the thigs flawlessely in just one simple click.</small> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-md-3 col-12 ml-2">
                <div className="card-content">
                    <div className="card-body"> <img className="img rck" src="/images/extra-spaces.png" />
                        <div className="card-title"> Remove extra spaces </div>
                        <div className="card-subtitle">
                            <p> <small className="text-muted">If you are worried with extra spaces added in your text and want to remove them. Just copy your text and paste it here and use our tool to remove extra spaces from your text.</small> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-md-3 col-12 ml-2">
                <div className="card-content">
                    <div className="card-body"> <img className="img rck" src="/images/count-words.png" />
                        <div className="card-title"> Count total words say word length </div>
                        <div className="card-subtitle">
                            <p> <small className="text-muted">If you are interested to know the total words used in your text. We are here to help you, just copy your text and paste it here. We will show you total word used in your text and other interesting information about your text.</small> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-md-3 col-12 ml-2">
                <div className="card-content">
                    <div className="card-body"> <img className="img rck" src="/images/count-characters.jpeg" />
                        <div className="card-title"> Count total charcaters say string length </div>
                        <div className="card-subtitle">
                            <p> <small className="text-muted">Counting all the characters used in your text could be a boring thing isn't it ? Sometimes we do it just for fun however at some point of times we just need it. Anyways whatever case it could be we are always here to help you. We will count all the characters used in your text and will show you.</small> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-md-3 col-12 ml-2">
                <div className="card-content">
                    <div className="card-body"> <img className="img rck" src="/images/time-to-read.jpeg" />
                        <div className="card-title"> Guess an estimated time to read your text </div>
                        <div className="card-subtitle">
                            <p> <small className="text-muted">We could also help you to find an estimated time to read the text. We are using a simple calculation login derived from google. We do not provide any surety on that however you can try that to check if that fits perfectly in your reading skills or not.</small> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-md-3 col-12 ml-2">
                <div className="card-content">
                    <div className="card-body"> <img className="img rck" src="/images/copy.png" />
                        <div className="card-title"> Copy your processed text for further use </div>
                        <div className="card-subtitle">
                            <p> <small className="text-muted">If you have used any of our above mentioned utility to process your string, then now its time to use that modified text. Once you have done all your processings you can copy the modifed text to your clipborad and paste it anywhere for further use.</small> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ft">
            <p className="chk text-light"><b>Looking for something more ?</b></p>
                <div className="btn btn-primary">
                    Don't worry, we are still in progress to add some more features to process your text
                </div>
        </div>
        <div className="info my-3 px-2 py-2">
            <b>Note : </b> We neither track your text nor save it anywhere. It is just an online tool to play with text, once your complete your processing its gone from here. However we recommend you not to process any sensitive text here which you conside to be more private.
        </div>
</div>
    </>
    )
}