import React, {useState} from 'react';

export default function TextForm(props) {

    const handleUpClick = ()=>{
        let newText = text.toUpperCase();
        setText(newText);
        if(text.length > 0)
        {
            props.showAlert('success','Text converted to uppercase!')
        }
    }

    const handleOnChange = (event)=>{
        setText(event.target.value);
    }

    const handleLowerClick = ()=>{
        let lowercase_text = text.toLowerCase();
        setText(lowercase_text);
        if(text.length > 0)
        {
            props.showAlert('success','Text converted to lowercase!')
        }
    }

    const handleCapitalizeEachWord = ()=>{
        let capitalized_text = text.split(' ').map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(' ');
        setText(capitalized_text);
        if(text.length > 0)
        {
            props.showAlert('success','First letter of each word capitalized!')
        }
    }

    const handleRemoveExtraSpaces = ()=>{
        let updated_text = text.split(' ').filter(n => n).join(' ');
        setText(updated_text);
        if(text.length > 0)
        {
            props.showAlert('success','Extra whitespaces has been removed!')
        }
    };

    const handleTextClear = ()=>{
        setText('');
        if(text.length > 0)
        {
            props.showAlert('success','Text cleared!')
        }
    }

    const handleCopyText = ()=>{
        let copy_text = document.getElementById('my_text');
        copy_text.select();
        copy_text.setSelectionRange(0,99999);
        navigator.clipboard.writeText(copy_text.value);
        if(text.length > 0)
        {
            props.showAlert('success','Text copied to clipboard!');
            document.getSelection().removeAllRanges();
        }
    }
    const [text,setText] = useState('');

    return (
        <>
        <div className="container my-5">
            <h2 className="mb-3 text-light">{ props.heading }</h2>
                <div className="mb-3">
                <textarea id="my_text" style={{backgroundColor:props.mode=='dark' ? 'grey' : 'white'}} className={`form-control text-${props.mode === 'light' ? 'dark' : 'light'}`} rows="8" value={text} onChange={handleOnChange}></textarea>
            </div>
            <button disabled={text.length === 0} onClick={handleUpClick} className="btn btn-primary">Convert to Uppercase</button>
            <button disabled={text.length === 0} onClick={handleLowerClick} className="btn btn-primary mx-1 my-1">Convert to Lowercase</button>
            <button disabled={text.length === 0} className="btn btn-primary mx-1 my-1" onClick={handleCapitalizeEachWord}>Capitalize Each Word</button>
            <button disabled={text.length === 0} className="btn btn-primary" onClick={handleRemoveExtraSpaces}>Remove Extra Spaces</button>
            <button disabled={text.length === 0} className="btn btn-primary mx-1 my-1" onClick={handleCopyText}>Copy Text</button>
            <button disabled={text.length === 0} className="btn btn-primary" onClick={handleTextClear}>Clear Text</button>
        </div>

        <div style={{color:props.text_color}} className={`container my-3 text-light`}>
            <h1>Your text summary</h1>
            <p><b>Words:</b> { text.replace(/\n/g,' ').split(' ').filter(n => n).length }</p>
            <p><b>Characters:</b> { text.length }</p>
            <p><b>Time to Read: </b> { 0.08 * text.replace(/\n/g,' ').split(' ').filter(n => n).length } </p>

            <h2 className="my-3">Preview</h2>
            <p> {text} </p>
        </div>
        </>
    )
}