import './App.css';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import Alert from './components/Alert';
import React, {useState} from 'react';
import About from './components/About';
import ContactUs from './components/ContactUs';
import ChatApp from './components/ChatApp';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
 } from "react-router-dom";

function App() {
  const [mode,setDarkMode] = useState('light');
  const [alert,setAlert] = useState(null);

    // Setting alert
    const showAlert = (type,message)=>{
      setAlert({
        msg: message,
        type: type
      });
      setTimeout(()=>{
        setAlert(null);
      },4000);
    }
  const toggleMode = ()=>{
    if(mode == 'light')
    {
      setDarkMode('dark');
      document.body.style.background = '#41464b';
      document.body.style.color = '#fff';
      showAlert('success','Dark mode has been enabled!');
    }
    else
    {
      setDarkMode('light');
      document.body.style.backgroundImage = 'linear-gradient(#3779b0,#ff002b4d)';
      showAlert('success','Light mode has been enabled!');
    }
  };

  return (
    <>
    <Router>
    <Navbar title="Text Utilities" mode={mode} toggleMode = {toggleMode} />
    <Alert alert={alert} />
      <Routes>
        <Route exact path="/" element={<TextForm heading = "Try textutils - Word counter, charcater counter, remove extra spaces etc" showAlert={showAlert} mode={mode} />}>
        </Route>
        <Route exact path="/about" element={<About />}>
        </Route>
        <Route exact path="/contact" element={<ContactUs mode={mode} />}>
        </Route>
        <Route exact path="/chat" element={<ChatApp />} ></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
