import React from 'react';

function Alert(props) {
    const capitalize = (word)=>{
        let word_in_lower = word.toLowerCase();
        return word_in_lower.charAt(0).toUpperCase() + word_in_lower.slice(1);
    }

    const handleCloseInfo = (event)=>{
        document.getElementById('error-pop-up').style.display = 'none';
    }
    return (
        <div style={{height:'50px'}}>
            { props.alert && <div id="error-pop-up" className={`alert alert-${props.alert.type}`} role="alert">
            <strong> {capitalize(props.alert.type)} </strong>{props.alert.msg}
            <button onClick={handleCloseInfo} type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> }
        </div>
    );
}

export default Alert;