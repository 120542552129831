import React from 'react';
import emailjs from '@emailjs/browser';

export default function ContactUs(props){
    const sendEmail = (e) => {
        e.preventDefault();
        var templateParams = {
            user_name:document.getElementById('contact_form_name').value,
            to_name:document.getElementById('contact_form_to_name').value,
            user_email:document.getElementById('contact_form_email').value,
            user_mobile:document.getElementById('contact_form_phone').value,
            needed_service:document.getElementById('services').value,
            user_message:document.getElementById('contact_form_message').value,
        };
        emailjs.send('service_nn8qdq6','template_pj6s0xq',templateParams,'0g4bTQRuLX6EOP6_i')
        .then((result) => {
            console.log(result);
        }, (error) => {
            console.log(error);
        });
    };
    return (
        <>
        <div className="container">
            <div className="contact_info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="contact_info_container d-flex flex-lg-row flex-column justify-content-between align-items-between">

                                { /* Contact Item */}
                                <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
                                    <div className="contact_info_image">
                                        <img src="https://img.icons8.com/office/24/000000/iphone.png" alt="" />
                                    </div>
                                    <div className="contact_info_content">
                                        <div className="contact_info_title text-dark">Phone</div>
                                        <div className="contact_info_text">+91 946 662 2010</div>
                                    </div>
                                </div>

                                { /* Contact Item */}
                                <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
                                    <div className="contact_info_image">
                                        <img src="https://img.icons8.com/ultraviolet/24/000000/filled-message.png" alt="" />
                                    </div>
                                    <div className="contact_info_content">
                                        <div className="contact_info_title text-dark">Email</div>
                                        <div className="contact_info_text">vishnudev40@gmail.com</div>
                                    </div>
                                </div>

                                { /* Contact Item */}
                                <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
                                    <div className="contact_info_image">
                                        <img src="https://img.icons8.com/ultraviolet/24/000000/map-marker.png" alt="" />
                                    </div>
                                    <div className="contact_info_content">
                                        <div className="contact_info_title text-dark">Address</div>
                                        <div className="contact_info_text">Noida, Sector 49, Uttar Pradesh, India</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            { /* Contact Form */}

            <div className="contact_form">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="contact_form_container">
                                <div className="contact_form_title">Get in Touch</div>

                                <form onSubmit={sendEmail} id="contact_form">
                                    <div className="contact_form_inputs d-flex flex-md-row flex-column justify-content-between align-items-between">
                                        <input name="user_name" type="text" id="contact_form_name" className="contact_form_name input_field" placeholder="Your name" required="required" data-error="Name is required." />
                                        <input name="user_email" type="text" id="contact_form_email" className="contact_form_email input_field" placeholder="Your email" required="required" data-error="Email is required." />
                                        <input name="user_mobile" type="text" id="contact_form_phone" className="contact_form_phone input_field" placeholder="Your phone number" />
                                        <input type="hidden" id="contact_form_to_name" value="Vishnu Dev Chourasiya" name="to_name" />
                                    </div>
                                    <div className="contact_form_inputs d-flex flex-md-row flex-column justify-content-between align-items-between">
                                        <select name="needed_service" id="services" className="input_field">
                                                <option value="">Needed Services</option>
                                                <option value="Core PHP Websites">Core PHP Websites</option>
                                                <option value="Laravel Websites">Laravel Websites</option>
                                                <option value="Wordpress Websites">Wordpress Websites</option>
                                                <option value="React Frontend">React Frontend</option>
                                                <option value="None of the above">None of the above</option>
                                        </select>
                                    </div>
                                    <div className="contact_form_text">
                                        <textarea id="contact_form_message" className="text_field contact_form_message" name="user_message" rows="4" placeholder="Message" required="required" data-error="Please, write us a message."></textarea>
                                    </div>
                                    <div className="contact_form_button">
                                        <button type="submit" className="button contact_submit_button">Send Message</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel"></div>
            </div>
        </div>
        </>
    );
}